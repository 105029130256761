import React from 'react'

function ButtonSecond(props) {
    return (
        <div>
            <div className="px-3 py-2 w-44 my-5 bg-body flex justify-center items-center rounded-md">
            <span className="text-primary font-bold">{props.text}</span>
            </div>
        </div>
    )
}

export default ButtonSecond
