import React from 'react'

function ContactUs() {
    return (
        <div>
            This is contact us page.... comming soon!!
        </div>
    )
}

export default ContactUs
