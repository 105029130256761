import "./App.css";
import Navbar from "./components/Navbar";
import ButtonPrimary from "./components/ButtonPrimary";
import ButtonSecond from "./components/ButtonSecond";
import { useState } from "react";
import axios from "axios";

function App() {
  const [useremail, setuseremail] = useState("");
  const [subject, setsubject] = useState("");
  const [inq, setinq] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://aikyaagro-z2vh3qxhxq-as.a.run.app/aikyaagro", {
        email: "info@aikyaagro.com", //"kish.jayasekera@gmail.com",
        useremail: useremail,
        subject: subject,
        inq: inq,
        ref: new Date().toISOString().split("T")[0],
      })
      .then((e) => {
        alert("Form Submitted Successfully");
      });
    axios
      .post("https://aikyaagro-z2vh3qxhxq-as.a.run.app/welcomeclient", {
        email: useremail,
      })
  };
  return (
    <div
      className="App min-h-screen w-screen"
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/background.jpeg"})`, backgroundRepeat: "repeat" }}
    >
      <div className=" w-full min-h-screen bg-black pb-4 bg-opacity-30 backdrop-blur-md pt-5 ">
        <Navbar />
        <div className="flex flex-col  items-center w-full  ">
          <div className="w-full ">
            <div>
              <div className="block lg:flex w-11/12 mx-auto">
                <div className=" lg:w-2/3">
                  <div className=" pt-10 lg:pt-0 lg:my-10 text-white space-y-14 flex flex-col items-center ">
                    <h1 className=" text-5xl font-bold ">Nourish Your Mind, <br /> Body and Soul with our Organic Produce.</h1>
                    <div className=" space-y-5">
                      <p className="text-lg text-left">
                        We are a Sri Lankan and US based Agri Business with approx 350 Acres under cultivation on our farms, in the Central and Southern parts of the island. </p>

                      <p className="text-lg text-left">Organic Cinnamon, Pepper, Vanilla, strawberries, assorted vegetables, mushrooms and Mango are some of the crops we presently have under direct cultivation and  we work with many out grower farming families to source other crops. </p>

                      <p className="text-lg text-left">Additionally we offer Farmstead holidays to guests who would like to experience a working farm, where guests can get first hand knowledge and experience.</p>

                      <p className="text-lg text-left">Please contact us on <a href="mailto:info@aikyaagro.com" type="email" className=" text-blue-700">info@aikyaagro.com</a>  or (Mobile/Whatsapp) <a href="tel:+94769715868" className=" text-blue-700" >+94769715868</a>  for inquiries on our produce and Farmstead holidays. </p>

                  {/* <button onClick={handleCall}> */}
                  <a href="tel:+94769715868 ">
                        <ButtonSecond text='Call Now' />
                      </a>
                      {/* </button> */}
                    </div>

                  </div>
                </div>

                <div className=" flex justify-center items-baseline mt-5">
                  <div className=" px-12 py-8 bg-body bg-opacity-75 h-26 lg:ml-24 mx-auto rounded-lg">
                    <h3 className="text-2xl font-semibold mb-6">Contact Us</h3>
                    <form className=" flex flex-col items-start" onSubmit={handleSubmit}>
                      <label className="font-medium" for="email">
                        Email Address
                      </label>
                      <input
                        className="bg-white w-full p-2 rounded-sm mt-2 mb-5"
                        value={useremail}
                        onChange={(e) => setuseremail(e.target.value)}
                        required
                        type="email"
                        id="email"
                        placeholder="Email Address"
                      />
                      <label className="font-medium" for="subject">
                        Subject
                      </label>
                      <input
                        className="bg-white w-full p-2 rounded-sm mt-2 mb-5"
                        value={subject}
                        onChange={(e) => setsubject(e.target.value)}
                        required
                        type="text"
                        id="subject"
                        placeholder="Subject"
                      />
                      <label className="font-medium" for="inquary">
                        Inquiry{" "}
                      </label>
                      <textarea
                        className="bg-white w-full p-2 rounded-sm mt-2 mb-5"
                        value={inq}
                        onChange={(e) => setinq(e.target.value)}
                        required
                        type="text"
                        id="inquary"
                        placeholder="Inquiry"
                        rows="4"
                        cols="50"
                      />
                      <div className="flex w-full justify-end">
                        <button type="submit">
                          <ButtonPrimary text="Submit" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
