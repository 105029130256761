import React, { PureComponent } from 'react'

export class Navbar extends PureComponent {
    render() {
        return (
            <div className=" bg-Transparent  w-screen ">
                <div className=" w-11/12 mx-auto justify-between flex items-center">
                   <div className="p-3 w-32 h-32 flex justify-center items-center bg-white bg-opacity-50 rounded-full">
                       <img src="/aikya-logo.png" alt="logo" className=" w-20"/>
                   </div>
                   {/* <ButtonPrimary text="Contact Us" /> */}
                </div>
            </div>
        )
    }
}

export default Navbar
