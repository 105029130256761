import React from 'react'

function ButtonPrimary(props) {
    return (
        <div className="px-3 py-2 bg-primary flex justify-center items-center rounded-md">
            <span className="text-white font-bold">{props.text}</span>
        </div>
    )
}

export default ButtonPrimary
